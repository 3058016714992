// Custom JS imports
// ... none at the moment

// Custom CSS imports
import '../css/custom.css';
import '../css/font-awesome-6.4.0.css';
import '../theme/css/colors/aqua.css';
import '../theme/css/plugins.css';
import '../theme/css/style.css';
import '../theme/css/fonts/thicccboi-v1.css';

console.log('The \'site\' bundle has been loaded!');